import { LoaderService } from 'src/app/core/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './core/authentication/authentication.service';
// import { ToasterConfig } from 'ngx-toastr';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  // public config: ToasterConfig = new ToasterConfig({
  //   animation: 'fade',
  //   positionClass: 'toast-top-full-width',
  //   showCloseButton: true,
  //   timeout: 8000
  // });

  public interval: any;

  private subscription: Subscription = new Subscription();


  constructor(private authenticationService: AuthenticationService, private router: Router, private loaderService: LoaderService) {
    this.subscription.add(router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    }));
  }

  ngOnInit(): void {
    this.subscription.add(this.authenticationService.obsLoginDetails.subscribe((data: any) => {
      if (data) {
        console.log("get csrf token")
        // this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        //   sessionStorage.setItem('csrfToken', data);
        // }));
        // this.intervalFunction();
      } else {
        // this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
        //   const currentApplicantUser = user ? user : null;
        //   if (currentApplicantUser && currentApplicantUser.isUser) {
        //     this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        //       sessionStorage.setItem('csrfToken', data);
        //     }));
        //     // this.intervalFunction();
        //   }
        // }));
      }
    }));
    this.subscription.add(this.authenticationService.obsLoggedOutBs.subscribe((data: any) => {
      if (data) {
        console.log("get csrf token")
        clearInterval(this.interval);
      }
    }))
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 7000)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  intervalFunction() {
    this.interval = setInterval(() => {
      this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        sessionStorage.setItem('csrfToken', data);
        console.log("csrf token value is ")
      }));
    }, 50000);
  }

}
