import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { appToaster } from 'src/app/configs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {

  public currentUser;
  public isApplicationEditable = false;
  public signatureFileName:string ;
  public isSignatureUploaded = false;
  public selectedFile: any;
  public isSubmit = false;

  @ViewChild('signatureUpl', { static: false }) signatureUpl: ElementRef;

  private subscription: Subscription = new Subscription();

  constructor(public authenticationService: AuthenticationService, private toasterService: ToastrService, private router: Router, private adminService: AdminService) { }

  ngOnInit() {
    this.interval() ;
  }
  intervalID:any;
  getUserDetail():any{
    this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
      this.currentUser = user ? user : null;
      if( this.currentUser){
        clearInterval(this.intervalID);
      }
    })
    );
  }
  interval(){
     this.intervalID = setInterval(() => {
      this.getUserDetail()
    }, 2000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickLogout() {
    this.authenticationService.logout();
    this.toasterService.success(appToaster.logoutSuccess);
    window.location.href = environment.alarmPermitHost;
    // this.router.navigate(['auth/user-landing'], {queryParams : {type : 'admin'}});
  }

  navigateToCityAdmin() {
    this.router.navigate(['admin/city-admin']);
  }

  navigateToStaffAdmin() {
    this.router.navigate(['admin/staff']);
  }

  navigateToAdminIndexPage() {
    this.router.navigate(['admin/index']);
  }
  navigateTomuted(){
    this.router.navigate(['admin/add-muted']);
  }

  signatureUpload() {
    const formData = new FormData();
    this.isSubmit = true;
    if (this.selectedFile) {
      formData.append('signature', this.selectedFile );
      this.subscription.add(this.adminService.managerUploadSignature(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        this.toasterService.success("Signature uploaded successfully");
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        }));
        this.signatureUpl.nativeElement.click();
        this.clear();
      }));
    } else {
      return false;
    }
  }

  clear() {
    this.isSubmit = false;
    this.isSignatureUploaded = false;
    this.selectedFile = null;
    this.signatureFileName = '';
  }

  selectFile(event) {
    this.selectedFile = event.target.files[0];
    this.signatureFileName = event.target.files[0].name;
    this.isSignatureUploaded = true
  }

  openUploadedSignature() {
    return;
    if (this.currentUser.signature) {
      window.open(environment.host + environment.imageBasePath + this.currentUser.signature, '_blank');
    }
  }

  deleteUploadedSignature() {
    if (this.currentUser.signature && this.currentUser.signature != 'null') {
      const formData = new FormData();
      formData.append('signature', null );
      this.subscription.add(this.adminService.managerUploadSignature(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        this.toasterService.success('Uploaded signature deleted successfully');
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        })
        );
      }));
      this.signatureUpl.nativeElement.click();
    }
  }

}
